import {
    Flex,
    Stack,
    Spinner,
} from '@chakra-ui/react';
import React from 'react';
import Header from './Header';

export default function Loader({ withHeader, withFlex, history, size }) {
    const spinnerSize = size || "xl"
    return (
        <>
            {withHeader && <Header />}
            {withFlex &&
                <Flex
                    minH={'90vh'}
                    align={'center'}
                    justify={'center'}
                >

                    <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size={spinnerSize}
                        />
                    </Stack>
                </Flex>
            }
            {!withFlex && <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size={spinnerSize}
            />}
        </>
    );
}