import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Container, Flex, Heading, Stack } from '@chakra-ui/layout';
import { withRouter } from 'react-router';
import { Header } from '../../components';

function Settings() {

    const SaveButton = ({ loading, onClick }) => {
        return (
            <Button
                isLoading={loading}
                loadingText="Searching"
                bg={'blue.400'}
                color={'white'}
                _hover={{
                    bg: 'blue.500',
                }}
                size="md"
                maxW="100px"
                marginTop="4px"
                onClick={onClick}
            >
                Save
            </Button>)
    }

    return (
        <>
            <Header />
            <Flex
                minH={'100vh-60px'}
                align={'center'}
                justify={'center'}
                bg={useColorModeValue('gray.50', 'gray.800')}>
                <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'}>Settings</Heading>
                    </Stack>

                    <Container maxW="xl" centerContent>
                        <Box
                            rounded={'xl'}
                            bg={useColorModeValue('white', 'gray.700')}
                            boxShadow={'xl'}
                            padding="6"
                            width="80vw"
                        >

                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                Password
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>

                                        <FormControl id="apiKey">
                                            <FormLabel>Current Password</FormLabel>
                                            <Input type="password" />
                                        </FormControl>

                                        <FormControl id="apiKey">
                                            <FormLabel>New Password</FormLabel>
                                            <Input type="password" />
                                        </FormControl>

                                        <FormControl id="apiKey">
                                            <FormLabel>Confirm New Password</FormLabel>
                                            <Input type="password" />
                                        </FormControl>

                                        <Stack spacing={10} align={'flex-end'}>
                                            <SaveButton onClick={() => { }} loading={false} />
                                        </Stack>
                                    </AccordionPanel>
                                </AccordionItem>
                                {/* <AccordionItem> 
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                API Settings
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>

                                        <FormControl id="apiKey">
                                            <FormLabel>APIKey</FormLabel>
                                            <Input type="text" />
                                            <FormHelperText>Please check your mail for apiKey.</FormHelperText>
                                        </FormControl>

                                        <Stack spacing={10} align={'flex-end'}>
                                            <SaveButton onClick={() => { }} loading={false} />
                                        </Stack>
                                    </AccordionPanel>
                                </AccordionItem> */}
                            </Accordion>
                        </Box>
                    </Container>
                </Stack>
            </Flex>
        </>
    );
}

export default withRouter(Settings)