const { BASE_API_URL = '' } = window._appConfig || {};

const getHeaders = () => {
    const authToken = localStorage.getItem("authToken");
    const headers = { 'Content-Type': 'application/json' };

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }
    return headers;
}
const GET = (endpoint) => {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(BASE_API_URL + endpoint, requestOptions);
}

const POST = (endpoint, data) => {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data)
    };
    return fetch(BASE_API_URL + endpoint, requestOptions);
}

const PATCH = (endpoint, data) => {
    const requestOptions = {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(data)
    };
    return fetch(BASE_API_URL + endpoint, requestOptions);
}

const DELETE = (endpoint, data) => {
    const requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(data)
    };
    return fetch(BASE_API_URL + endpoint, requestOptions);
}

export {
    GET,
    POST,
    PATCH,
    DELETE
}