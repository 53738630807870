import { BrowserRouter as Router, Switch } from 'react-router-dom';
import withAuth from '../hocs/withAuth';
import { BulkSearch, Login, QuickSearch, Settings, SomethingWentWrong } from '../pages'
import PublicRoute from './PublicRoute';

const routes = [
    {
        type: 'PUBLIC',
        path: '/somethingwentwrong',
        component: <SomethingWentWrong/>
    },
    {
        type: 'PUBLIC',
        path: '/login',
        component: withAuth(Login, true, "/")
    },
    {
        type: 'PUBLIC',
        path: '/quick-search',
        component: withAuth(QuickSearch)
    },
    {
        type: 'PUBLIC',
        path: '/bulk-search',
        component: withAuth(BulkSearch)
    },
    {
        type: 'PUBLIC',
        path: '/settings',
        component: withAuth(Settings)
    },
    {
        type: 'PUBLIC',
        path: '/',
        component: withAuth(QuickSearch)
    },
]

export function RegisterRoutes() {
    return (
        <Router>
            <Switch>
                {routes.map(route => {
                    switch (route.type) {
                        case 'PUBLIC':
                            return <PublicRoute path={route.path} component={route.component} />
                        case 'PRIVATE':
                            return <PublicRoute path={route.path} component={route.component} />
                        default:
                            return (<></>)
                    }
                })}
            </Switch>
        </Router>
    )
}