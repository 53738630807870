import React from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

export default function Dropzone({ setProcessing, onSuccess, onError }) {

    const dropzoneStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
    }
    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: 'text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFiles: 1
    })

    const parseTextFile = (fileName, fileContent) => {
        const _numbers = fileContent.split(/\n/);
        const numbers = _numbers
            .map(number => number.trim())
            .filter(number => /^\d+$/.test(number))
        setProcessing(false)
        onSuccess(fileName, numbers)
    }

    const parseExcelFile = (fileName, fileContent) => {
        console.log('parseExcelFile ', fileName)
        const wb = XLSX.read(fileContent, { type: 'binary' });
        const [sheetName] = wb.SheetNames
        if (!sheetName) {
            setProcessing(false)
            onError("No sheets present in Excel")
        }
        const ws = wb.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_csv(ws, { header: 0 });
        const _data = data.split(/\n/);
        const numbers = _data
            .map(row => row ? row.split(",")[0] : "")
            .filter(number => /^\d+$/.test(number))
        setProcessing(false)
        onSuccess(fileName, numbers)
    }

    const processSelectedFile = (file) => {
        setProcessing(true)
        const { name = '' } = file
        const reader = new FileReader()
        reader.onabort = () => {
            setProcessing(false)
            onError('File reading was aborted')
        }
        reader.onerror = () => {
            setProcessing(false)
            onError('File reading has failed')
        }
        reader.onload = () => {
            const content = reader.result
            switch (file.type) {
                case 'text/plain':
                    return parseTextFile(name, content)
                case 'application/vnd.ms-excel':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return parseExcelFile(name, content)
                default:
                    console.log(file)
            }
        }
        switch (file.type) {
            case 'text/plain':
                reader.readAsText(file)
                break
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                reader.readAsBinaryString(file)
                break
            default:
        }
    }

    React.useEffect(() => {
        if (fileRejections.length > 0) {
            console.log(fileRejections)
            onError("Only .txt and .xls files are supported")
            return
        }
        if (acceptedFiles.length !== 1) {
            return
        }
        const [file] = acceptedFiles
        processSelectedFile(file)
    }, [acceptedFiles, fileRejections])

    return (
        <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop .xls or .txt file here, or click to select file ( Maximum 10000 Numbers )</p>
        </div>
    );
}