import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { HTTPClient } from '../utils'
import { Loader } from '../components';

export default function withAuth(WrappedComponent, isLoginPage = false, redirectTo = '/') {
    function HOC({ history }) {
        const [loading, setLoading] = React.useState(false);
        const [loggedIn, setLoggedIn] = React.useState(false);
        const [loginInfo, setLoginInfo] = React.useState({});

        const authToken = localStorage.getItem("authToken");
        React.useEffect(() => {
            setLoading(true);
            HTTPClient.GET('/auth/current-user')
                .then(response => {
                    if (response.status === 200) {
                        const responseJSON = response.json()
                        return responseJSON
                    } else {
                        localStorage.removeItem('authToken');
                        if (!isLoginPage) {
                            history.push('/login');
                        }
                    }
                })
                .then(responseJSON => {
                    if (responseJSON) {
                        const { data = {} } = responseJSON
                        setLoginInfo(data)
                        setLoggedIn(true);

                        if (isLoginPage) {
                            history.push(redirectTo);
                        }
                    } else {
                        localStorage.removeItem('authToken');
                    }
                })
                .catch((error) => {
                    console.log('[withAuth][ERROR]', error)
                    setLoggedIn(-1);
                });
        }, [authToken, history]);

        React.useEffect(() => {
            if (isLoginPage) {
                setLoading(false)
            }
            else if (!isLoginPage && loggedIn) {
                setLoading(false);
            }
        }, [loggedIn]);

        if (!isLoginPage && loggedIn === -1) {
            return <Redirect
                to={{
                    pathname: "/somethingwentwrong",
                }}
            />
        }

        if (loading) {
            return (
                <Loader
                    withHeader={true}
                    withFlex={true}
                />
            )
        }
        return <WrappedComponent loginInfo={loginInfo} />;
    };
    return withRouter(HOC)
}