import { Alert, AlertIcon } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Search2Icon } from '@chakra-ui/icons';
import { Box, Container, Flex, Heading, Stack } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { Collapse } from '@chakra-ui/transition';
import React from 'react';
import { withRouter } from 'react-router';
import { Header, PaginatedTable } from '../../components';
import { HTTPClient } from '../../utils';
import * as XLSX from 'xlsx';

function QuickSearch() {
    const numbersRef = React.createRef()
    const [searching, setSearching] = React.useState(false)
    const [searchError, setSearchError] = React.useState("");
    const [showSearchResult, setShowSearchResult] = React.useState(false);
    const [searchResult, setSearchResult] = React.useState([]);

    const checkNumbersInput = (e) => {
        if ((e.which < 48 || e.which > 57) & e.which !== 8 && e.which !== 44) {
            e.preventDefault();
            return false;
        }
    }

    const processQuickSearch = () => {
        const numbersText = numbersRef.current ? numbersRef.current.value : ''
        if (!numbersText) {
            setSearchError("Please enter some number to search")
            setTimeout(() => setSearchError(""), 5000)
            return
        }

        const numbers = numbersText.split(",")
        if (numbers.length > 10) {
            setSearchError("Maximum 10 numbers allowed in quick search")
            setTimeout(() => setSearchError(""), 5000)
            return
        }
        setSearching(true)
        HTTPClient.POST('/search/quick', { numbers })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setSearchError("Search Failed")
                    setTimeout(() => setSearchError(""), 5000)
                }
                setSearching(false)
            })
            .then(jsonResponse => {
                const { data = {} } = jsonResponse
                setSearchResult(data)
                setSearching(false)
                setShowSearchResult(true)
            })
            .catch(() => setSearching(false));
    }

    const exportSearchResultsTable = () => {
        const b64toBlob = (base64, type = 'application/vnd.ms-excel') =>
            fetch(`data:${type};base64,${base64}`).then(res => res.blob())

        const table = document.getElementById('quick-search-results-table');
        const wb = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
        const base64Sheet = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'base64' })
        const blobSheet = b64toBlob(base64Sheet)
        blobSheet
            .then(blob => URL.createObjectURL(blob))
            .then(bloblURL => {
                window.location = bloblURL;
            })
            .catch(error => { console.log("[exportSearchResultsTable][ERROR]", error) })
    }

    const SearchResultsTable = () => {
        const columns = React.useMemo(
            () => [
                {
                    Header: 'Results',
                    columns: [
                        {
                            Header: 'Number',
                            accessor: 'number',
                        },
                        {
                            Header: 'Name',
                            accessor: 'name',
                        },
                        {
                            Header: 'Search Warnings',
                            accessor: 'searchWarnings',
                        },
                        {
                            Header: 'Spam Score',
                            accessor: 'spamScore',
                        },
                        {
                            Header: 'Spam Type',
                            accessor: 'spamType',
                        }
                    ],
                },
            ],
            []
        )

        return <>
            <Stack spacing={4} align={'flex-end'} flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'}>
                <Button
                    bg={'red.400'}
                    color={'white'}
                    _hover={{
                        bg: 'red.500',
                    }}
                    size="md"
                    maxW="100px"
                    marginTop="4px"
                    onClick={() => {
                        setShowSearchResult(false)
                        setSearchResult([])
                    }}
                >
                    Clear
                        </Button>
                <Button
                    // isLoading={loading}
                    loadingText="Searching"
                    bg={'green.400'}
                    color={'white'}
                    _hover={{
                        bg: 'green.500',
                    }}
                    size="md"
                    maxW="100px"
                    marginTop="4px"
                    onClick={exportSearchResultsTable}
                >
                    Download
                </Button>
            </Stack>
            <PaginatedTable
                tableID={'quick-search-results-table'}
                columns={columns}
                data={searchResult}
                disablePagination={true}
            />
        </>
    }

    const QuickSearchForm = () => {
        return (
            <>
                <Collapse initialScale={0.9} in={searchError !== ""}>
                    <Alert status="error"><AlertIcon />{searchError}</Alert>
                </Collapse>
                <Textarea
                    ref={numbersRef}
                    onKeyPress={checkNumbersInput}
                    placeholder="Multiple mobile numbers separated by comma ( , )"
                />
                <Stack spacing={10} align={'flex-end'}>
                    <Button
                        leftIcon={<Search2Icon />}
                        isLoading={searching}
                        loadingText="Searching"
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                            bg: 'blue.500',
                        }}
                        size="md"
                        maxW="100px"
                        marginTop="4px"
                        onClick={processQuickSearch}
                    >
                        Search
                    </Button>
                </Stack>
            </>
        )
    }
    return (
        <>
            <Header />
            <Flex
                minH={'100vh-60px'}
                align={'center'}
                justify={'center'}
                bg={useColorModeValue('gray.50', 'gray.800')}>
                <Stack spacing={8} mx={'auto'} maxW={'auto'} py={12} px={6}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'}>Quick Search</Heading>
                    </Stack>

                    <Container maxW="xl" centerContent>
                        <Box
                            rounded={'xl'}
                            bg={useColorModeValue('white', 'gray.700')}
                            boxShadow={'xl'}
                            padding="6"
                            width="80vw"
                        >
                            {showSearchResult ? <SearchResultsTable /> : <QuickSearchForm />}
                        </Box>
                    </Container>
                </Stack>
            </Flex>
        </>
    );
}

export default withRouter(QuickSearch)