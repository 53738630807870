import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Container, Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import { Header, Dropzone, Loader, PaginatedTable } from '../../components';
import React from 'react';
import { Alert, AlertIcon } from '@chakra-ui/alert';
import { Collapse } from '@chakra-ui/transition';
import { Button } from '@chakra-ui/button';
import { withRouter } from 'react-router';
import { HTTPClient } from '../../utils';
import { Progress } from '@chakra-ui/progress';
const { EXCEL_API_URL = '' } = window._appConfig || {};

function BulkSearch({ history }) {

    const [fileUploadError, setFileUploadError] = React.useState([])
    const [processing, setProcessing] = React.useState(false)
    const [showPreview, setShowPreview] = React.useState(false)
    const [selectedFile, setSelectedFile] = React.useState("")
    const [numbersList, setNumbersList] = React.useState([])

    const [creatingTask, setCreatingTask] = React.useState(false)

    const [searchingTask, setSearchingTask] = React.useState(false)
    const [activeBulkTask, setActiveBulkTask] = React.useState({})
    const [markingComplete, setMarkingCompleted] = React.useState(false)

    /* File Upload */
    const handleSelectedFile = (fileName, numbers) => {
        if (numbers.length === 0) {
            handlerFileUploadError([
                `No numbers found in ${fileName}`,
                `Please make sure numbers are available in First Sheet First Row in case of Excel and 1 Number per line in case of txt file`
            ])
            return;
        }
        handlerFileUploadError([])

        setSelectedFile(fileName)
        setNumbersList(numbers)
        setShowPreview(true)
    }
    const handlerFileUploadError = (error) => {
        const errorsArray = Array.isArray(error) ? error : [error]
        setFileUploadError(errorsArray)
        if (errorsArray.length > 0) {
            setTimeout(() => setFileUploadError([]), 5000)
        }
    }

    const FileUploadBox = () => {
        return (
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                paddingTop={4}
            >

                <Stack spacing={4}>
                    <Collapse initialScale={0.9} in={fileUploadError.length !== 0}>
                        {fileUploadError.map(error => (
                            <Alert status="error"><AlertIcon />{error}</Alert>
                        ))}
                    </Collapse >
                    {processing ?
                        <Flex align={'center'} justify={'center'}>
                            <Loader />
                        </Flex> :
                        <Dropzone
                            setProcessing={setProcessing}
                            onSuccess={handleSelectedFile}
                            onError={handlerFileUploadError}
                        />}
                </Stack>
            </Box>
        )
    }

    /* Preview Table */
    const createBulkSearchTask = (numbers) => {
        setCreatingTask(true);
        HTTPClient.POST('/search/bulk', { numbers })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                setCreatingTask(false)
            })
            .then(jsonResponse => {
                setCreatingTask(false)
                fetchActiveBulkTask();
            })
            .catch(() => setCreatingTask(false));
    };

    const PreviewTable = () => {
        const columns = React.useMemo(
            () => [
                {
                    Header: 'Number',
                    columns: [
                        {
                            Header: '',
                            accessor: 'number',
                        }
                    ],
                },
            ],
            []
        )
        const numbersData = React.useMemo(() => numbersList.map(number => ({ number })), [])

        return <>
            <Container maxW="xl" centerContent>
                <Box
                    rounded={'xl'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'xl'}
                    padding="6"
                    width="80vw"
                >
                    <Stack spacing={4} align={'flex-end'} flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'}>
                        <Button
                            bg={'red.400'}
                            color={'white'}
                            _hover={{
                                bg: 'red.500',
                            }}
                            size="md"
                            maxW="100px"
                            marginTop="4px"
                            onClick={() => {
                                setShowPreview(false)
                                setSelectedFile("")
                                setNumbersList([])
                            }}
                            disabled={creatingTask}
                        >
                            Cancel
                        </Button>
                        <Button
                            isLoading={creatingTask}
                            loadingText="Creating..."
                            bg={'blue.400'}
                            color={'white'}
                            _hover={{
                                bg: 'blue.500',
                            }}
                            size="md"
                            maxW="100px"
                            marginTop="4px"
                            onClick={() => {
                                createBulkSearchTask(numbersList)
                            }}
                        >
                            Confirm
                        </Button>
                    </Stack>
                    <PaginatedTable columns={columns} data={numbersData} fileName={selectedFile} />
                </Box>
            </Container>
        </>
    }

    /* Bulk Task */
    const fetchActiveBulkTask = () => {
        setSearchingTask(true)
        HTTPClient.GET('/search/bulk/task')
            .then(response => {
                if (response.status === 200) {
                    const responseJSON = response.json()
                    return responseJSON
                } else {
                    setSearchingTask(false)
                    history.push("/somethingwentwrong")
                }
            }).then(jsonResponse => {
                const { data = {} } = jsonResponse
                if (!data) {
                    setSearchingTask(false)
                    return
                }
                setSearchingTask(false)
                setActiveBulkTask(data)
            })
            .catch((error) => {
                setSearchingTask(false)
                console.log("[bulkTaskFetch][ERROR]", error)
                history.push("/somethingwentwrong")
            });
    }

    const markBulkTestComplete = (taskID) => {
        setMarkingCompleted(true)
        HTTPClient.PATCH(`/search/bulk/${taskID}/complete`, {})
            .then(response => {
                if (response.status === 200) {
                    const responseJSON = response.json()
                    return responseJSON
                } else {
                    setMarkingCompleted(false)
                    history.push("/somethingwentwrong")
                }
            }).then(() => {
                setMarkingCompleted(false)
                setActiveBulkTask({})
                fetchActiveBulkTask()
            })
            .catch((error) => {
                setMarkingCompleted(false)
                console.log("[bulkTaskFetch][ERROR]", error)
                history.push("/somethingwentwrong")
            });
    }

    const ActiveBulkTask = () => {
        const { _id: taskID = '', status = '', progress = -1, numbersCount = -1 } = activeBulkTask || {}

        const progressPercentage = Math.round((progress / numbersCount) * 100)
        return <>
            <Container maxW="xl" centerContent>
                <Box
                    rounded={'xl'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'xl'}
                    padding="6"
                    width="80vw"
                >
                    {status === 'PROCESSED' &&
                        <Stack spacing={4} align={'flex-end'} flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'}>
                            <Button
                                bg={'green.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'green.500',
                                }}
                                size="md"
                                maxW="100px"
                                marginTop="4px"
                                onClick={() => {
                                    window.location = EXCEL_API_URL.replace("<taskID>", activeBulkTask._id)
                                }}
                            >
                                Download
                        </Button>
                            <Button
                                isLoading={markingComplete}
                                loadingText="Processing..."
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}
                                size="md"
                                marginTop="4px"
                                onClick={() => {
                                    markBulkTestComplete(activeBulkTask._id)
                                }}
                            >
                                Search another file
                        </Button>
                        </Stack>
                    }

                    <Stack spacing={4}>
                        <Text fontSize="xl">Task ID : {taskID}</Text>
                        <Text fontSize="xl">Task Status : {status}</Text>
                        <Text fontSize="xl">Number Count : {numbersCount}</Text>
                        <Text fontSize="xl">Numbers Processed : {progress}</Text>
                        <Progress colorScheme="green" height="32px" value={progressPercentage} />
                    </Stack>
                </Box>
            </Container>
        </>
    }

    React.useEffect(() => {
        fetchActiveBulkTask();
    }, [])

    return (
        <>
            <Header />
            <Flex
                minH={'100vh-60px'}
                align={'center'}
                justify={'center'}
                bg={useColorModeValue('gray.50', 'gray.800')}>
                {searchingTask && <Loader withFlex={true} />}
                {!activeBulkTask._id && !searchingTask &&
                    <Stack spacing={8} mx={'auto'} maxW={'auto'} py={'12'} px={8}>
                        <Stack align={'center'}>
                            {showPreview
                                ? <Heading fontSize={'4xl'}>{selectedFile} - {numbersList.length} Numbers</Heading>
                                : <Heading fontSize={'4xl'}>Bulk Search</Heading>}
                        </Stack>
                        {showPreview ? <PreviewTable /> : <FileUploadBox />}

                    </Stack>
                }
                {activeBulkTask._id && !searchingTask &&
                    <Stack spacing={8} mx={'auto'} maxW={'auto'} py={'12'} px={8}>
                        <Stack align={'center'}>
                            <Heading fontSize={'4xl'}>Bulk Search Status</Heading>
                        </Stack>
                        <ActiveBulkTask />
                    </Stack>
                }
            </Flex>
        </>
    );
}

export default withRouter(BulkSearch)