import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Button,
    Heading,
    useColorModeValue,
    Collapse,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import React from 'react';
import { withRouter } from 'react-router';
import { Header } from '../../components';
import { HTTPClient } from '../../utils';

function Login({ history }) {

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [loginError, setLoginError] = React.useState("");

    const processLogin = () => {
        setLoading(true);
        HTTPClient.POST('/auth/login', { username, password })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setLoginError("Login Failed")
                    setTimeout(() => setLoginError(""), 5000)
                }
                setLoading(false)
            })
            .then(jsonResponse => {
                const { data = {} } = jsonResponse
                const { token = '' } = data
                localStorage.setItem("authToken", token)
                history.push('/');
            })
            .catch(() => setLoading(false));
    };

    return (
        <>
            <Header />
            <Flex
                minH={'100vh-60px'}
                align={'center'}
                justify={'center'}
                bg={useColorModeValue('gray.50', 'gray.800')}>

                <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'}>Sign in to your account</Heading>
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('white', 'gray.700')}
                        boxShadow={'lg'}
                        p={8}>

                        <Stack spacing={4}>
                            <Collapse initialScale={0.9} in={loginError !== ""}>
                                <Alert status="error"><AlertIcon />{loginError}</Alert>
                            </Collapse >
                            <FormControl id="email">
                                <FormLabel>Username</FormLabel>
                                <Input type="text" onChange={(e) => setUsername(e.target.value)} />
                            </FormControl>
                            <FormControl id="password">
                                <FormLabel>Password</FormLabel>
                                <Input type="password" onChange={(e) => setPassword(e.target.value)} />
                            </FormControl>
                            <Stack spacing={10}>
                                <>
                                    <Stack
                                        direction={{ base: 'column', sm: 'row' }}
                                        align={'start'}
                                        justify={'space-between'}>
                                        <Checkbox>Remember me</Checkbox>
                                    </Stack>
                                    <Button
                                        isLoading={loading}
                                        loadingText="Logging in..."
                                        bg={'blue.400'}
                                        color={'white'}
                                        _hover={{
                                            bg: 'blue.500',
                                        }}
                                        onClick={processLogin}
                                    >
                                        Sign in
                                        </Button>
                                </>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Flex>
        </>
    );
}

export default withRouter(Login)